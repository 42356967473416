import pageHeader from '@c/pageHeader.vue'
import pageFooter from '@c/pageFooter.vue'
import breadcrumbs from '@c/breadcrumbs.vue'
import pageStep from '@c/pageStep.vue'
import sideImgList from '@c/sideImgList.vue'
import sideCommodity from '@c/sideCommodity.vue'
import sideInformation from '@c/sideInformation.vue'
import { getCommodityDetail } from '@api/product'
export default {
    name: 'ProductDetail',
    components: {
        pageHeader,
        pageFooter,
        breadcrumbs,
        pageStep,
        sideImgList,
        sideCommodity,
        sideInformation
    },
    data() {
        return {
            num: 1,
            stepData: [
                { up: '2019年10月18日', down: '预订开始，拍下定金', active: true },
                { up: '2019年10月20日', down: '商品出荷，等待补款通知', active: false },
                { up: '2019年10月22日', down: '补款出货，请填写收货地址', active: false }
            ],
            detail: {},
            commodity: {},
            commodityDetail: [],
            contentList: [],
            commodityid: '',
            commodityDetailsId: ''
        }
    },
    methods: {
        handleChange(value) {
            console.log(value)
        },
        // 获取详情
        async getData(commodityid) {
            const res = await getCommodityDetail({
                commodityid
            })
            if (res && res.list) {
                this.detail = Object.assign({}, this.detail, res.list)
                this.commodity = Object.assign({}, this.commodity, this.detail.commodity)
                this.commodityDetail = Object.assign({}, this.commodityDetail, this.detail.commodity_detail)
                this.contentList = this.detail.information.list
            }
            console.log('product detail', this.detail)
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log(to, from)
        next(vm => {
            console.log(vm.$route.query)
            vm.commodityid = vm.$route.query.commodityid
            vm.commodityDetailsId = vm.$route.query.commodity_details_id
            if (vm.commodityid) {
                vm.getData(vm.commodityid)
            }
        })
    }
}
