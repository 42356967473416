<template>
    <div class="step-box">
        <div v-for="item in stepData" :key="item.name" class="step-item" :class="{active: item.active}" :style="{ width: `calc(100% / ${stepData.length})` }">
            <span class="step-txt step-txt-up">{{ item.up }}</span>
            <i class="step-icon" />
            <span class="step-txt step-txt-down">{{ item.down }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Step',
    props: {
        stepData: {
            type: Array,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
.step-box {
    margin: 0 auto;
    padding: 0 90px;
    padding-bottom: 45px;
}
.step-item {
    @include inlineBlock();
    height: 45px;
    position: relative;
    border-bottom: 4px solid $light;
    > * {
        display: block;
    }
}
.step-icon {
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border: 4px solid $light;
    border-radius: 50%;
    background-color: $white;
}
.step-txt {
    width: 100%;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $light;
}
.step-txt-down {
    top: auto;
    bottom: -45px;
}
.step-item {
    &.active .step-icon {
        border-color: $red;
    }
    &:first-child {
        .step-icon {
            left: 0;
        }
        .step-txt {
            left: 0;
        }
    }
    &:last-child {
        .step-icon {
            left: auto;
            right: -20px;
        }
        .step-txt {
            left: auto;
            right: -100%;
        }
    }
}
</style>
